import React from "react"
import { Link } from "gatsby"

import "./styles.scss"

const NotFound = () => {
  return (
    <div className="notFound">
      <h1 className="notFound-h1">404</h1>
      <p className="notFound-text">NOT FOUND</p>
      <Link className="notFound-link" to="/en">
        Return Home
      </Link>
    </div>
  )
}

export default NotFound
